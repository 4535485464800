import request from '@/api/index'

// 获取项目列表
export function getProjectList (params:object) {
  return request({
    url: '/web/user/project/list/',
    method: 'GET',
    params
  })
}
// 选择项目
export function selectProject (data:object) {
  return request({
    url: '/web/user/selected/',
    method: 'POST',
    data
  })
}
