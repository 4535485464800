






















import { Vue, Component, Prop } from 'vue-property-decorator'

interface Columns {
  text: string;
  field: string;
}

interface TableHeader {
  name: string;
  nameEn: string;
  isShow: boolean;
}

@Component
export default class TheTable extends Vue {
  @Prop(Array) tableHeader!: TableHeader;
  @Prop(Array) tableData: any;
  @Prop(Boolean) isBaseDB: any;

  private warningDialog: boolean = false;
  private removeId: number = 0;

  get query () {
    return this.$route.query
  }

  private selectAll (value: any): void {
    this.$emit('selectAll', value)
  }
  private selected (item: any): void {
    this.$emit('selectedItem', item)
  }
  private viewSource (row: any): void {
    this.$router.push({
      path: '/source-view',
      query: {
        DBId: this.query.DBId || '0',
        DBName: this.query.DBName || '全部',
        record: row._patient_id,
        visit: row._visit_id,
        formDataId: row._form_data_id
      }
    })
  }

  private addToChildrenDB (row: any) {
    this.$store.commit('dataQuery/changeSignleData', {
      id: row._form_data_id,
      isSingle: true
    })
    this.$emit('addToChildrenDBSingle', row._form_data_id)
  }
  private removeForChildrenDB (row: any) {
    this.warningDialog = true
    this.removeId = row._form_data_id
  }
  private confirmRemove () {
    const params = {
      formId: this.query.selectedDB,
      idList: [this.removeId]
    }
  }
}
