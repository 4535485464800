














































































import { Watch, Vue, Component } from 'vue-property-decorator'
import ProjectTable from '@/components/table/TheTable.vue'
import { getProjectList, selectProject } from '@/api/modules/account'
import { AxiosResponse } from 'axios'
// import { ProjectElement } from '@/model/project'
import Cookie from 'js-cookie'
import { setSessionStorage } from '@/utils/storage'
@Component({
  name: 'changeProject',
  components: { ProjectTable }
})
export default class extends Vue {
  private recentData: object = [];
  private allData: object = [];
  private tableHeader: object = [{ text: '项目名称', slot: 'name' }];
  private page: any = {
    currentPage: 1,
    pageSize: 10
  };
  private total: number = 0;
  private status: any = null;
  @Watch('page', { deep: true })
  onChangePage () {
    this.projectList()
  }
  created () {
    this.projectList()
  }
  private projectList () {
    if (Cookie.get('id')) {
      let params = {
        userId: Cookie.get('id'),
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize
      }
      getProjectList(params).then((res: any) => {
        this.recentData = res.data.recent
        this.allData = res.data.all.list
        this.status = res.data.status
        this.total = res.data.all.total
      })
    }
  }
  private selectProject (id: number, name: string) {
    let data = {
      userId: Cookie.get('id'),
      projectId: id
    }
    selectProject(data).then((res: any) => {
      if (res.code === 0) {
        Cookie.set('projectId', id)
        Cookie.set('centerId', 0)
        setSessionStorage('projectName', name)
        this.$router.push('admin/main')
      }
    })
  }
  // 分页
  private changePage (value: number): void {
    this.page.currentPage = value
  }
  private changeSize (value: number): void {
    this.page.pageSize = value
  }
}
